<template>
  <div>
    <v-data-table :headers="headers" :items="filteredPacotes" hide-default-footer show-select v-model="selectedRows" show-expand
      :expanded.sync="expanded" >
      <template v-slot:[`item.data-table-select`]="{ isSelected, select, on, attrs }">
        <v-simple-checkbox :value="isSelected" @click="() => select(!isSelected)" v-on="on" v-bind="attrs">
          {{ isSelected }}
        </v-simple-checkbox>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td colspan="4" style="background-color: #f5f5f5;">
          <ProcedureFormEditCopyItemsExpand :operadora="item.operadora.id" :pacote="item.id" />
        </td>
      </template>
    </v-data-table>
    <div class="d-flex justify-end">
      <ConfirmButton :onConfirm="duplicatePacote" :loading="isSubmitting" color="success">Salvar
      </ConfirmButton>
    </div>
  </div>
</template>

<script>
import UtilsFunc from '@/service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';
import ProcedureFormEditCopyItemsExpand from './ProcedureFormEditCopyItemsExpand.vue';
import api from '../../../../http';

export default {
  name: "ProcedureFormEditCopyItems",
  props: {
    pacotes: Array,
    onClose: Function,
    idoperadora: Number,
    idpacote: Number,
    nomePacote: String,
    inicioVigencia: String
  },
  data: () => UtilsFunc.withCRUDUtils({
    selectedRows: [],
    expanded: [],
    headers: [
      { text: "Id", align: "center", value: "id", width: 20,  },
      { text: "Pacote", align: "center", value: "nome" },
    ]
  }),
  computed: {
    isSubmitting() {
      return this.isLoading('post:procedures')
    },
    filteredPacotes() {
      // Filtra pacotes que possuem 'ativo' como true
      return this.pacotes.filter(pacote => pacote.ativo && pacote.id !== this.idpacote);
    }
  },
  methods: {
   async duplicatePacote() {
    const LOADING = 'post:procedures';
    this.setLoading(LOADING);
    try {
      // Filtrar apenas os pacotes que o usuário selecionou
      const selectedPackages = this.selectedRows;

      if (selectedPackages.length === 0) {
        this.$toast.error('Selecione pelo menos um pacote para copiar.');
        return;
      }

      // Obtem os procedimentos de cada pacote selecionado
      const proceduresPromises = await Promise.all(
        selectedPackages.map(async (row) =>
          await api.get(`/operadoras/operadora-itempacotes/?operadora=${row.operadora.id}&pacote=${row.id}`)
        )
      );

      // Consolida os procedimentos retornados
      const procedures = proceduresPromises.reduce((acc, res) => {
        acc.push(...res.data);
        return acc;
      }, []);

      // Filtra apenas os itens ativos dos pacotes selecionados
      const activeProcedures = procedures.filter(proc => proc.ativo);

      if (activeProcedures.length === 0) {
        this.$toast.error('Nenhum item ativo encontrado nos pacotes selecionados.');
        return;
      }

      // Faz a cópia dos itens ativos
      await Promise.all(
        activeProcedures.map(async (proc) => {
          const body = {
            ...proc, 
            preco: this.getItemPreco(proc), 
            inicio_vigencia: this.inicioVigencia, 
            ativo: true,
            operadora: this.idoperadora,
            pacote: this.idpacote,
            procedimento: this.getProcedimento(proc),
            equipamento: this.getEquipamento(proc),
            taxa: this.getTaxa(proc)
          };
          return await api.post('/operadoras/operadora-itempacotes/', body);
        })
      );

      this.$toast.success('Itens copiados com sucesso!');
      this.onClose();
    } catch (error) {
      this.$toast.error('Falha ao copiar itens');
    } finally {
      this.setLoading(LOADING, true);
    }
  },
    getTaxa(proc) {
      if (!proc.taxa) return null
      return proc.taxa.id
    },
    getProcedimento(proc) {
      if (!proc.procedimento) return null
      return proc.procedimento.id
    },
    getEquipamento(proc) {
      if (!proc.equipamento) return null
      return proc.equipamento.id
    },
    getPacote(proc) {
      if (!proc.pacote) return null
      return proc.pacote.id
    },
    getItemPreco(pacote) {
      if (pacote.taxa) return pacote.taxa.preco;
      if (pacote.equipamento) return pacote.equipamento.preco;
      if (pacote.procedimento) return pacote.procedimento.preco;
      return 0;
    },
  },
  mounted() {
    // console.log(this.pacotes)
  },
  components: { ConfirmButton, ProcedureFormEditCopyItemsExpand }
}
</script>

