<template>
  <!-- Modal para selecionar operadora, mês e ano -->
  <v-dialog v-model="showModal" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="blod_color">Selecionar Operadora, Mês e Ano para gerar o indicador</span>
      </v-card-title>

      <v-card-text>
        <v-form>
          <!-- Mostrar um spinner enquanto as operadoras estão carregando -->
          <v-progress-circular v-if="loadingOperadoras" indeterminate color="primary"></v-progress-circular>

          <!-- Mostrar o select das operadoras quando estiverem disponíveis -->
          <v-row v-else>
          <v-col >
              <v-select v-model="selectedOperadora" :items="operadoras" item-text="nome" item-value="id" label="Operadora" required outlined dense ></v-select>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="selectedMes" label="Mês" required type="Number" outlined dense ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="selectedAno" label="Ano" required type="Number" outlined dense ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="cancelModal">Cancelar</v-btn>
        <v-btn color="green" text @click="confirmModal">Confirmar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
  import api from "@/http";
  import jsPDF from "jspdf";
  import "jspdf-autotable";
  // import moment from "moment";
  import { mapGetters } from 'vuex';
  import UtilsPDF from '../../../service/utilsPDF';
  import logo from "../../../assets/logo.png";
  import ona from "../../../assets/ONA_Nivel_2.png";
  import gptw from "../../../assets/GPTW_2023-2024.png";


  export default {
    name: "IndicadorOperadora",
    data() {
      return {
        loading: false,
        loadingOperadoras: false,
        // showModal: false,
        selectedOperadora: null,
        selectedMes: '',
        selectedAno: '',
        operadoras: [],
        // meses: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // Meses disponíveis
        // anos: [2022, 2023, 2024],
        };
    },
    props: {
     showModal: {
      type: Boolean,
      required: true
    }
    },
    methods: {
      openModal() {
      if (this.operadoras.length === 0) {
        this.loadingOperadoras = true; // Mostra o loading enquanto carrega
        this.getOperadoras();
      }
    },
    cancelModal() {
      this.$emit('update:showModal', false); // Emite o evento para fechar o modal
    },
    confirmModal() {
      if (!this.selectedOperadora || !this.selectedMes || !this.selectedAno) {
        this.$toast.error("Por favor, preencha todos os campos.");
        return;
      }
      this.$emit('update:showModal', false); // Fecha o modal
      this.getPDF(); // Gera o PDF
    },
      async getOperadoras() {
        try {
          const { data } = await api.get('operadoras/operadoras/');
          this.operadoras = data;
          this.loadingOperadoras = false; // Carregamento concluído
        } catch (error) {
          this.$toast.error(`Erro ao buscar dados das Operadoras: ${error}`);
          this.loadingOperadoras = false; // Em caso de erro, ocultar o loading
        }
      },
      async getPDF() {
        this.loading = true;
        this.$toast.success(`O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`);
        
        try {
          // Chame a API com os dados selecionados no modal
          const { data } = await api.get(`operadoras/indicadores-operadora/?operadora=${this.selectedOperadora}&mes=${this.selectedMes}&ano=${this.selectedAno}`);
          this.indicador = data; // Carrega os dados do indicador
          // this.getOperadoras();
          if (!this.indicador || this.indicador.length === 0) {
            this.$toast.error('Nenhum indicador encontrado para a operadora selecionada.');
            this.loading = false;
            return;
          }

          const doc = this.generatePDF();
          const indicador = this.indicador[0];
          const operadoranome = indicador.operadora_nome || "Operadora não informada";

          // Salva o PDF diretamente após a geração
          const filename = `Relatorio_Indicador_${operadoranome}.pdf`;
          doc.save(filename); // Salva o PDF no navegador

          this.loading = false;
        } catch (error) {
          this.$toast.error("Erro ao carregar os dados:", error);
          this.loading = false;
        }
      },
      generatePDF() {
        const doc = new jsPDF({ compress: true });
        this.drawHeader(doc);

        // Incluir as informações básicas da operadora
        const indicador = this.indicador[0];
        // console.log(indicador)
        const operadoranome = indicador.operadora_nome || "Operadora não informada";
        const currentDate = new Date();  
        const mes = currentDate.getMonth(); 
        const ano = currentDate.getFullYear(); 

        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text(`Relatório da Operadora ${operadoranome}, analisando o mês de ${mes}/${ano}.`, 10, 50);
        // doc.setFont("helvetica", "normal");
        // doc.text(`Analise do mês ${mes} / ${ano}`, 10, 60);

        // Gerar os dados de quantidade por modalidade
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text("Resumo por Modalidade", 15, 60); // Ajustar a posição à esquerda
        doc.text("Resumo por Faixa Etária", 85, 60); // Ajustar a posição à esquerda
        doc.text("Grupo Diagnóstico", 154, 60);

  // Tabela com as modalidades e reinternaç˜qo
        const modalidades = {};
        let totalID = 0; // Para contar os "ID"
        let totalAD = 0; // Para contar os "AD"
        let totalDiasInternacao = 0;
        let totalDiasAD = 0;
        let totalCustointernacao = 0;
        let custointernacao = 0;
        let reinternacaoID = 0;
        let reinternacaoAD = 0;
        let reprovadoID = 0;
        let reprovadoAD = 0;
        let novopacienteID = 0;
        let novopacienteAD = 0;
        let liminarID = 0;
        let liminarAD = 0;
        let paliativoID = 0;
        let paliativoAD = 0;
        let respiradorID = 0;
        let respiradorAD = 0;
      

  
        
        this.indicador.forEach(item => {
          modalidades[item.modalidade] = (modalidades[item.modalidade] || 0) + 1;
          // Contabilizar ID e AD
          if (item.modalidade_id === '4' || item.modalidade_id === '5'|| item.modalidade_id === '3' || item.modalidade_id === '1') {
            totalID += 1;
            totalDiasInternacao += item.qtd_diarias || 0;
            totalCustointernacao += parseFloat(item.valor_conta) || 0 ;
            custointernacao += item.valor_conta;
            reinternacaoID += item.reinternacao === true;
            reprovadoID +=item.reprovado === true;
            novopacienteID += item.atendimento_mes_anterior === true;
            liminarID += item.liminar === true;
            paliativoID += item.paliativo === true;
            respiradorID += item.respirador === true;
            
          } else {
            totalAD += 1;
            totalDiasAD += item.qtd_diarias || 0;
            reinternacaoAD += item.reinternacao === true;
            reprovadoAD +=item.reprovado === true;
            novopacienteAD += item.atendimento_mes_anterior === true;
            liminarAD += item.liminar === true;
            paliativoAD += item.paliativo === true;
            respiradorAD += item.respirador === true;
            
          }
        });
        let reinternacao = reinternacaoID + reinternacaoAD
        let reprovado = reprovadoID + reprovadoAD
        let novopaciente = novopacienteID + novopacienteAD
        const totalPacientes = totalID + totalAD;  // Total de pacientes

        const rows = Object.keys(modalidades).map(key => {
          const totalModalidade = Object.values(modalidades).reduce((sum, value) => sum + value, 0);
          const quantidade = modalidades[key];
          const percentual = ((quantidade / totalModalidade) * 100).toFixed(2) + '%'; // Calcula o percentual
          return [key, quantidade, percentual,]; // Retorna a modalidade, quantidade e percentual
        });

        // Calcula o total de pacientes somando os valores das modalidades
        const total = Object.values(modalidades).reduce((sum, value) => sum + value, 0);

        // Desenhar a tabela QTD por Modalidade
        doc.autoTable({
          head: [['Modalidade', 'Qtd.*', '%']],
          body: rows,
          startY: 65, // Posição vertical ajustada
          tableWidth: 65, // Largura reduzida para 1/3 da página
          styles: {
            fontSize: 10, // Reduzir o tamanho da fonte
            valign: 'middle', // Alinha verticalmente no centro
            lineColor: [44, 62, 80], // Cor das bordas das células
            lineWidth: 0.1, // Espessura das bordas
          },
          headStyles: {
            fillColor: [52, 152, 219], // Cor de fundo do cabeçalho
            textColor: [255, 255, 255], // Cor do texto do cabeçalho
            fontStyle: 'bold',
            halign: 'center', // Centraliza o texto do cabeçalho
          },
          bodyStyles: {
            halign: 'center', // Centraliza o texto nas células do corpo
          },
          footStyles: {
            fillColor: [236, 240, 241], // Cor de fundo do rodapé
            textColor: [0, 0, 0], // Cor do texto do rodapé
            fontStyle: 'bold',
          },
          foot: [
            [{ content: 'Total', styles: { halign: 'right', fillColor: [236, 240, 241], fontStyle: 'bold' } }, 
            { content: total.toString(), styles: { halign: 'center', fillColor: [236, 240, 241] } }]
          ],
          margin: { left: 5 }, // Posiciona a tabela à esquerda
        });

        // Calcular percentuais de ID e AD
        const percentualID = ((totalID / totalPacientes) * 100).toFixed(0);
        const percentualAD = ((totalAD / totalPacientes) * 100).toFixed(0);

        // Incluir a frase com percentuais de ID e AD
        doc.setFont("helvetica", "normal");
        doc.setFontSize(8);
        doc.text(`* Qtd. Quantidade de paciente por modalidade`, 5, doc.autoTable.previous.finalY + 3);
        doc.setFontSize(10);
        doc.text(`AD é composta pelo SAD, TED e LE.`, 5, doc.autoTable.previous.finalY + 8);
        // doc.text(`* Qtd. Quantidade de paciente por faixa etária`, 80, 145);
        doc.text(`Tivemos ${percentualID}% em ID e ${percentualAD}% em AD`, 5, doc.autoTable.previous.finalY + 13);

  
  // Tabela de Faixa Etária 
        // Função para calcular a idade com base na data de nascimento
        function calcularIdade(dataNascimento) {
          const nascimento = new Date(dataNascimento);
          const hoje = new Date();
          let idade = hoje.getFullYear() - nascimento.getFullYear();
          const mes = hoje.getMonth() - nascimento.getMonth();
          if (mes < 0 || (mes === 0 && hoje.getDate() < nascimento.getDate())) {
            idade--;
          }
          return idade;
        }

        // Definir as faixas etárias
        const faixasEtarias = [
          { faixa: '0 - 09 anos', min: 0, max: 10 },
          { faixa: '10 - 19 anos', min: 10, max: 19 },
          { faixa: '20 - 29 anos', min: 20, max: 29 },
          { faixa: '30 - 39 anos', min: 30, max: 39 },
          { faixa: '40 - 49 anos', min: 40, max: 49 },
          { faixa: '50 - 59 anos', min: 50, max: 59 },
          { faixa: '60 - 69 anos', min: 60, max: 69 },
          { faixa: '70 - 79 anos', min: 70, max: 79 },
          { faixa: '80 anos ou mais', min: 80, max: 200 }
        ];

        // Distribuir os pacientes nas faixas etárias
       const faixaEtariaContagem = faixasEtarias.map(faixa => {
          const quantidade = this.indicador.filter(item => {
            const idade = calcularIdade(item.data_nascimento);
            return idade >= faixa.min && idade <= faixa.max;
          }).length;
          
          // Se a quantidade for maior que 0, converte para string, senão retorna string vazia
          const quantidadeFormatada = quantidade > 0 ? quantidade.toString() : ''; 
          
          // Calcula o percentual, se houver quantidade
          const percentual = quantidade > 0 ?  (((quantidade / totalPacientes) * 100).toFixed(2) + '%') : ''; 
          
          // Retorna a faixa etária, quantidade formatada e percentual
          return [faixa.faixa, quantidadeFormatada, percentual];  
        });


        // Desenhar a tabela por faixa Etária
        doc.autoTable({
          head: [['Faixa Etária', 'Qtd.*', '%']],
          body: faixaEtariaContagem,
          startY: 65, // Posição ajustada para o lado da outra tabela
          margin: 80, // Posicionar ao lado da tabela de modalidades
          tableWidth: 60,
          styles: {
            fontSize: 10,
            halign: 'center',
            valign: 'middle',
            lineColor: [44, 62, 80],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [52, 152, 219],
            textColor: [255, 255, 255],
            fontStyle: 'bold',
            halign: 'center',
          },
          bodyStyles: {
            halign: 'center',
          },
          footStyles: {
            fillColor: [236, 240, 241],
            textColor: [0, 0, 0],
            fontStyle: 'bold',
          }
        });

        // Nova Tabela de CID
        const cidData = [
            { nome: 'Mental', quantidade: this.indicador.filter(item => [99, 100, 101, 108, 114, 115, 121, 127, 133].includes(Number(item.cid_id))).length },
            { nome: 'Circulatório', quantidade: this.indicador.filter(item => [212, 213, 214, 218, 225, 229, 232, 234, 237, 239, 241].includes(Number(item.cid_id))).length },
            { nome: 'Digestivo', quantidade: this.indicador.filter(item => [299, 300, 312, 454].includes(Number(item.cid_id))).length },
            { nome: 'Endócrino', quantidade: this.indicador.filter(item => [94, 95, 1481, 98].includes(Number(item.cid_id))).length },
            { nome: 'Geniturinário', quantidade: this.indicador.filter(item => [412, 413, 420].includes(Number(item.cid_id))).length },
            { nome: 'Infecção', quantidade: this.indicador.filter(item => [23, 24, 22, 26, 37, 49].includes(Number(item.cid_id))).length },
            { nome: 'Neoplasia', quantidade: this.indicador.filter(item => [54, 1484, 56, 59, 60, 63, 65, 68, 73, 74, 76, 1485, 85, 86].includes(Number(item.cid_id))).length },
            { nome: 'Neurológico', quantidade: this.indicador.filter(item => [137, 138, 1482, 139, 1483, 140, 143, 157, 158, 159, 160].includes(Number(item.cid_id))).length },
            { nome: 'Osteomolecular', quantidade: this.indicador.filter(item => [349, 356, 357, 358, 360, 366, 375, 376, 380, 381, 384, 394, 396, 402, 409, 457, 481, 496, 506, 522, 526, 546, 556, 563].includes(Number(item.cid_id))).length },
            { nome: 'Pele', quantidade: this.indicador.filter(item => [339, 342, 535].includes(Number(item.cid_id))).length },
            { nome: 'Respiratório', quantidade: this.indicador.filter(item => [260, 269, 272, 273, 281, 282, 286].includes(Number(item.cid_id))).length }
        ];
        // Calcula a quantidade de "Outros" CID que não se enquadram nas categorias anteriores
        const totalCIDs = this.indicador.length;
        const cidsClassificados = cidData.reduce((acc, cid) => acc + cid.quantidade, 0);
        const quantidadeOutros = totalCIDs - cidsClassificados;
        cidData.push({ nome: 'Outros', quantidade: quantidadeOutros });
        // // Adiciona a linha "Outros" caso haja CIDs que não se encaixam em nenhuma categoria
        // if (quantidadeOutros > 0) {
         
        // }
        const cidRows = cidData.map(cid => [cid.nome, cid.quantidade === 0 ? '' : cid.quantidade.toString()]);
        
        doc.autoTable({
            head: [['Grupo Diagnóstico', 'Qtd.*']],
            body: cidRows,
            startY: 65,
             margin: { top: 65, left: 150 }, // Ajuste a margem à esquerda para mover a tabela mais à direita
              tableWidth: 'wrap',
            styles: {
              fontSize: 10,
              halign: 'center',
              valign: 'middle',
              lineColor: [44, 62, 80],
              lineWidth: 0.1,
            },
            headStyles: {
              fillColor: [52, 152, 219],
              textColor: [255, 255, 255],
              fontStyle: 'bold',
              halign: 'center',
            },
            bodyStyles: {
              halign: 'center',
            },
            footStyles: {
              fillColor: [236, 240, 241],
              textColor: [0, 0, 0],
              fontStyle: 'bold',
            }
        });

// Parte das Analises 
        // Verifique se os valores são numéricos, caso contrário, use 0 como fallback.
        let custointernacaobase = totalCustointernacao / totalDiasInternacao;
        custointernacao = parseFloat(custointernacaobase) || 0;
        const custoHospitalarPorDia = 3000; // Custo médio por dia de internação hospitalar
        const totalEconomia = (custoHospitalarPorDia * totalDiasInternacao) - custointernacao;

        const perentualReinternacaoID = ((reinternacaoID / totalID) * 100).toFixed(2); // Calcula o percentual
        const perentualReinternacaoAD = ((reinternacaoAD / totalAD) * 100).toFixed(2); // Calcula o percentual

        // Garantir que o totalEconomia seja sempre um número
        const economiaFormatada = totalEconomia > 0 ? totalEconomia.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '0,00';

        if(totalID > 0 ){
          let currentY = doc.autoTable.previous.finalY + 10; // Começando logo após a tabela
          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          doc.text(`Análise de tempo de internação e Economia`, 10, currentY);
          
          currentY += 5; // Incrementa para próxima linha
          doc.setFont("helvetica", "normal");
          doc.setFontSize(10);
          doc.text(`Na modalidade ID - Internação Domiciliar houve ao todo ${totalDiasInternacao} dias.`, 12, currentY);
          
          currentY += 5; // Incrementa para próxima linha
          doc.text(`Isso gerou uma economia para a operadora de mais de R$ ${economiaFormatada}, considerando o custo médio de`, 12, currentY);
          
          currentY += 5;
          doc.text(`Internação Hospitalar no Brasil. (valoreconomico.com.br)`, 12, currentY);

          currentY += 5;
          doc.setFontSize(8);
          doc.text(`* O valor da economia é calculado multiplicando o custo médio de internação hospitalar pela quantidade de diárias em ID, subtraindo o custo total `, 12, currentY);
          currentY += 5;
          doc.text(`desse período com os pacientes na modalidade ID.`, 12, currentY);
          
          if(totalDiasAD > 0 ) {
            currentY += 5;
            doc.setFontSize(10);
            doc.text(`Na modalidade AD - Assistência Domiciliar, tivemos uma média de ${totalDiasAD} dias de atendimento.`, 12, currentY);
          }
      // Liminar
          if(liminarID > 0 ) {
            currentY += 5;
            doc.setFontSize(10);
            doc.text(`Temos em atendimento ${liminarID} paciente(s) que estão por força de liminar em ID. ${liminarAD > 0 ? `Em AD temos ${liminarAD} pacientes` : ''}`, 12, currentY);
          } else {
            currentY += 5;
            doc.setFontSize(10);
            doc.text(`Não temos em atendimento paciente que estão por força de liminar em ID.`, 12, currentY);
          }
          if((liminarAD > 0) && (liminarID = 0)) {
            currentY += 5;
            doc.setFontSize(10);
            doc.text(`Temos em atendimento ${liminarID} paciente(s) que estão por força de liminar em AD.`, 12, currentY);
          } 
      // Paliativo
          if(paliativoID > 0 ) {
            currentY += 5;
            doc.setFontSize(10);
            doc.text(`Temos ${paliativoID} paciente(s) que estão no programa PALIATIVO em ID. ${paliativoAD > 0 ? `Em AD temos ${paliativoAD} pacientes` : ''}`, 12, currentY);
          } else {
            currentY += 5;
            doc.setFontSize(10);
            doc.text(`Não temos em atendimento paciente que estão no programa de PALIATIVO.`, 12, currentY);
          }
          if((paliativoAD > 0) && (paliativoID = 0)) {
            currentY += 5;
            doc.setFontSize(10);
            doc.text(`Temos ${paliativoID} paciente(s) que estão estão no programa PALIATIVO em AD`, 12, currentY);
          } 
      // Repirador
          if(respiradorID > 0 ) {
            currentY += 5;
            doc.setFontSize(10);
            doc.text(`Temos ${respiradorID} paciente(s) que faz uso de respirador em ID. ${respiradorAD > 0 ? `Em AD temos ${respiradorAD} pacientes` : ''}`, 12, currentY);
          } else {
            currentY += 5;
            doc.setFontSize(10);
            doc.text(`Não temos em atendimento paciente que faz uso de respirador.`, 12, currentY);
          }
          if((respiradorAD > 0) && (respiradorID = 0)) {
            currentY += 5;
            doc.setFontSize(10);
            doc.text(`Temos ${respiradorID} paciente(s) que faz uso de respirador em AD`, 12, currentY);
          } 
          
          currentY += 10; // Espaço extra antes do próximo título
          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          doc.text(`Análise das Reinternações`, 10, currentY);
          doc.setFontSize(10);
          if (reinternacao > 0) {
              currentY += 5;
              doc.setFont("helvetica", "normal");
              doc.setFontSize(10);
              doc.text(`Análise dos pacientes em ID.`, 11, currentY);
              
              if (reinternacaoID > 0) {
                  currentY += 5;
                  doc.text(`Tivemos ${reinternacaoID} reinternação(s) hospitalar(s), representando ${perentualReinternacaoID}% dos ID.`, 12, currentY);
              } else {
                  currentY += 5;
                  doc.text(`Devido ao grande empenho da equipe, não tivemos internação hospitalar na modalidade ID.`, 12, currentY);
              }
              
              currentY += 5;
              doc.text(`Análise dos pacientes em AD.`, 11, currentY);
              
              if (reinternacaoAD > 0) {
                  currentY += 5;
                  doc.text(`Tivemos ${reinternacaoAD} reinternação(s) hospitalar(s), representando ${perentualReinternacaoAD}% dos AD.`, 12, currentY);
              } else {
                  currentY += 5;
                  doc.text(`Devido ao empenho da equipe, não tivemos internação hospitalar na modalidade AD.`, 12, currentY);
              }
          } else {
              currentY += 5;
              doc.setFont("helvetica", "normal");
              doc.text(`Devido ao grande empenho da equipe, não tivemos internação hospitalar nesse período.`, 12, currentY);
          }
          
          currentY += 10; // Espaço extra antes da seção de reprovados
          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          doc.text(`Análise de orçamentos enviados`, 10, currentY);
          doc.setFontSize(10);
          if (reprovado > 0) {
              doc.setFont("helvetica", "normal");
              
              if (reprovadoID > 0) {
                  currentY += 5;
                  doc.text(`Tivemos ${reprovadoID} orçamento(s) não autorizados na modalidade ID.`, 12, currentY);
              }
              if (reprovadoAD > 0) {
                  currentY += 5;
                  doc.text(`Na modalidade AD, tivemos ${reprovadoAD} orçamento(s) reprovado(s).`, 12, currentY);
              }
          } else {
              currentY += 5;
              doc.setFont("helvetica", "normal");
              doc.text(`Não tivemos orçamentos reprovados nesse período.`, 12, currentY);
          }
          currentY += 10; // Espaço extra antes da seção de reprovados
          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          doc.text(`Novos Pacientes`, 10, currentY);
          doc.setFontSize(10);
          // console.log(novopaciente)
          if (novopaciente > 0) {
              doc.setFont("helvetica", "normal");
              
              if (novopacienteID > 0) {
                  currentY += 5;
                  doc.text(`Na modalidade ID tivemos ${novopacienteID} paciente(s) novo(s) iniciando os atendimentos no mês analisado.`, 12, currentY);
              } 
              if (novopacienteAD > 0) {
                  currentY += 5;
                  doc.text(`Na modalidade AD tivemos ${novopacienteAD} paciente(s) novo(s) iniciando os atendimentos no mês analisado.`, 12, currentY);
              }
          } else {
              currentY += 5;
              doc.setFont("helvetica", "normal");
              doc.text(`Não tivemos novos pacientes iniciando os atendimentos no mês de analisado.`, 12, currentY);
          }
  // parte de AD 
        } else {
          let currentY = doc.autoTable.previous.finalY + 8;
          doc.setFont("helvetica", "bold");
          doc.setFontSize(10);

          doc.text(`Análise de tempo de internação e Economia. `, 10,currentY);

          currentY += 5;
          doc.setFont("helvetica", "normal");
          doc.text(`Não tivemos nenhum atendimento de ID - Internação Domiciliar nessa período analisado.`, 12, currentY);
          currentY += 5;
          doc.text(`Todos os atendimentos foram de AD - Assistência Domicliar.`, 12, currentY);
          currentY += 5;
          doc.text(`Na modalidade AD - Assistência Domiciliar, tivemos uma média de ${totalDiasAD} dias de atendimento. `, 12, currentY);
          currentY += 5; // Espaço extra antes do próximo título
      // Liminar
         
         if(liminarAD > 0 ) {
            
            doc.setFontSize(10);
            doc.text(`Temos em atendimento ${liminarID} paciente(s) que estão por força de liminar em AD.`, 12, currentY);
          } else {
            
            doc.setFontSize(10);
            doc.text(`Não temos em atendimento paciente que estão por força de liminar em AD.`, 12, currentY);
          }
          
      // Paliativo
          if(paliativoAD > 0 ) {
            currentY += 5;
            doc.setFontSize(10);
            doc.text(`Temos ${paliativoID} paciente(s) que estão no programa PALIATIVO em AD. `, 12, currentY);
          } else {
            currentY += 5;
            doc.setFontSize(10);
            doc.text(`Não temos em atendimento paciente que estão no programa de PALIATIVO em AD.`, 12, currentY);
          }
          
      // Repirador
          if(respiradorAD > 0 ) {
            currentY += 5;
            doc.setFontSize(10);
            doc.text(`Temos ${respiradorID} paciente(s) que faz uso de respirador em AD.`, 12, currentY);
          } else {
            currentY += 5;
            doc.setFontSize(10);
            doc.text(`Não temos em atendimento paciente que faz uso de respirador em AD.`, 12, currentY);
          }
          
          currentY += 10;
          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          doc.text(`Análise das Reinternações`, 10, currentY);
          doc.setFontSize(10);
          if (reinternacaoAD > 0) {
              currentY += 5;
              doc.setFont("helvetica", "normal");
              doc.setFontSize(10);
              doc.text(`Análise dos pacientes em AD.`, 11, currentY);
              currentY += 5;
              doc.text(`Tivemos ${reinternacaoAD} reinternação(s) hospitalar(s), representando ${perentualReinternacaoAD}% dos AD - Assistência Domiciliar.`, 12, currentY);
      
          } else {
              currentY += 5;
              doc.setFont("helvetica", "normal");
              doc.text(`Devido ao grande empenho da equipe, não tivemos internação hospitalar nesse período.`, 12, currentY);
          }
          
          currentY += 10; // Espaço extra antes da seção de reprovados
          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          doc.text(`Análise de orçamentos enviados`, 10, currentY);
          doc.setFontSize(10);
          if (reprovado > 0) {
              doc.setFont("helvetica", "normal");
              if (reprovadoAD > 0) {
                  currentY += 5;
                  doc.text(`Na modalidade AD, tivemos ${reprovadoAD} orçamento(s) reprovado(s).`, 12, currentY);
              }
          } else {
              currentY += 5;
              doc.setFont("helvetica", "normal");
              doc.text(`Não tivemos orçamentos reprovados nesse período.`, 12, currentY);
          }
          currentY += 10; // Espaço extra antes da seção de reprovados
          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          doc.text(`Novos Pacientes`, 10, currentY);
          doc.setFontSize(10);
          if (novopacienteAD > 0) {
              doc.setFont("helvetica", "normal");
            
              if (novopacienteAD > 0) {
                  currentY += 5;
                  doc.text(`Na modalidade AD tivemos ${novopacienteAD} paciente(s) novo(s) iniciando os atendimentos no mês analisado.`, 12, currentY);
              } 
          } else {
              currentY += 5;
              doc.setFont("helvetica", "normal");
              doc.text(`Não tivemos novos pacientes iniciando os atendimentos no mês em analise.`, 12, currentY);
          }
        }


        UtilsPDF.addPageNumbers(doc);

        return doc; // Retorna o objeto doc
      },
    

      drawHeader(doc) {
        try {
          doc.addImage(logo, 10, 8, 28, 28);
          doc.addImage(ona, 160, 10, 15, 15);
          doc.addImage(gptw, 185, 10, 15, 15);
        } catch (error) {
          this.$toast.error("Falha ao adicionar a imagem ao PDF:", error);
        }

        doc.setFont("helvetica", "bold");
        doc.setFontSize(18);
        doc.text("SÃO LUÍS HOME CARE", 105, 22, { align: "center" });
        doc.setFontSize(16);
        doc.text("RELATÓRIO DE INDICADOR MENSAL", 105, 35, { align: "center" });
      },

      async download(dataurl, filename) {
        const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl
        const res = await fetch(httpsurl)
        const buffer = await res.blob()
        const url = URL.createObjectURL(buffer)
        const link = document.createElement("a");
        link.download = filename;
        link.href = url;
        link.click();
      },
      lista() {
        return this.userData || {}
      },
    },
    computer:{ ...mapGetters(['userData']),},
    mounted(){
      this.getOperadoras()
    },
    watch: {
    showModal(val) {
      if (val) {
        this.openModal(); // Abre o modal quando showModal for true
      }
    }
  },
  };
  </script>
